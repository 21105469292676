import React from "react";
import Hero from "../components/Index/Hero";
import Features from "../components/Index/Features";
import Info from "../components/Index/Info";
import Pricing from "../components/Index/Pricing";
import Gallery from "../components/Index/Gallery";
import Testimonials from "../components/Index/Testimonials";
import Blog from "../components/Index/Blog";
import useTitle from "../components/hooks/useTitle";

const Index = () => {
  useTitle("Home");
  return (
    <div className="index-page" id="home">
      <span>
      <Hero />
      </span>
    <span id="product">  <Features /></span>
    <span id="info"> <Info /></span>
    <span id="subscription"><Pricing /></span>
    <span id="product"> <Gallery /></span>
    <span id="testimonial"> <Testimonials /></span>
    <span id="blog"> <Blog /></span>
    </div>
  );
};

export default Index;
