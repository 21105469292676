import React from "react";
import {
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
} from "../../imgs/slider/index";
const Gallery = () => {
  const images = [slide1, slide2, slide3, slide4, slide5,slide1, slide2, slide3, slide4, slide5,slide1, slide2, slide3, slide4, slide5,slide1, slide2, slide3, slide4, slide5];

  return (
    <div className="gallery-section">
      <div className="container py-5 d-flex flex-col align-items-center">
        <h1 className="fs-1 font-bold leading-tight mt-2">
          <center>
            Our <span className="orange-font">Photo Gallery</span>
          </center>
        </h1>
      </div>
      <div className="slider">
        <div className="slide-track">
          {images.map((image, index) => (
            <div className="slide" key={index}>
              <img
                src={image}
                height="100"
                width="250"
                alt={`slide${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
