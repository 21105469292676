// MainLayout.tsx
import React, { useState } from "react";
import Navbar from "../../components/header/Navbar";
import { Footer } from "../../components/footer/Footer";

interface MainLayoutProps { 
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [issidebaropen, setIssidebaropen] = useState(false);
  return (
    <div className="main-layout">
      <Navbar setIssidebaropen={setIssidebaropen} />

      <main
        className={`main-section ${
          issidebaropen ? "background-blur-effect" : ""
        }`}
      >
        {children}
      </main>

      <Footer issidebaropen={issidebaropen} />
    </div>
  );
};

export default MainLayout;
