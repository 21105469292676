// Sidebar.tsx
import React, { useState } from 'react';
import closeicon from '../../imgs/close.png';
import { Link, NavLink } from 'react-router-dom';
import logo from "../../imgs/logo-dark.png";
import { toast } from 'react-toastify';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('');

  function hideSidebar() {
    onClose()
  }

  const opentoastmessage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toast.info(
      <div>
        <p>Interested in a closer look? Request a demo or gain early access now!</p>
      </div>,
      { position: "top-center", autoClose: 4000, hideProgressBar: true }
    );
  }

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='p-4 d-flex'>
        <img src={logo} alt="My Awesome Website" className="logo" />
        <img src={closeicon} onClick={onClose} className={`${isOpen ? 'sidenav-close-btn-open' : 'sidenav-close-btn'}`} alt='close' />

      </div>

      <hr className='text-[#b5b5b5] mt-[-3px]'></hr>
     
      <Link to="/"  className={`menu-item mt-2 ${activeMenuItem === 'home' || activeMenuItem === '' ? 'active' : ''}`}  onClick={() => { setActiveMenuItem('home');hideSidebar() }}>
            Home
          </Link>
          <Link to="/#product"  className={`menu-item ${activeMenuItem === 'product' ? 'active' : ''}`} onClick={() => {setActiveMenuItem('product');hideSidebar() }}>
            Our Product
          </Link>
          <Link to="/#subscription"  className={`menu-item ${activeMenuItem === 'subscription' ? 'active' : ''}`} onClick={() => {  setActiveMenuItem('subscription');hideSidebar()}}>
            Subscription
          </Link>
          <Link to="/#testimonial" className={`menu-item ${activeMenuItem === 'testimonial' ? 'active' : ''}`} onClick={() => { setActiveMenuItem('testimonial');hideSidebar() }} >
            Testimonial
          </Link>
          <NavLink to="/give" className="menu-item" onClick={() => { setActiveMenuItem('give');hideSidebar() }}>
            Give
          </NavLink>
      <div className="p-3">
        <button onClick={e => opentoastmessage(e)} data-tooltip-id="login-tooltip" data-tooltip-content="Interested in a closer look? Request a demo or gain early access now!" className="ring-offset-background focus-visible:ring-ring inline-flex h-[45px] w-[100%] items-center justify-center whitespace-nowrap rounded-full bg-gray-300 text-gray-600 px-4 py-2 text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" style={{ cursor: "default" }}>
          Log In
        </button>

      </div>
    </div>
  );
};

export default Sidebar;
