// App.tsx
import React from "react";
import "./App.css";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./pages/Index";
import MainLayout from "./pages/Layout/MainLayout";
import { Give } from "./pages/Give";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <MainLayout>
        <Routes>
          <Route index element={<Index />} />
          <Route path="/give" element={<Give />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
