import React, { useState } from "react";
import LocationInput from "../LocationInput/LocationInput";
import { IoCloseCircle } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { toast } from "react-toastify";

const SignUpForEarlyAccess = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [organizationname, setOrganizationName] = useState("");
  const [organizationsize, setOrganizationSize] = useState("");
  const [location, setLocation] = useState("");
  const [process, setProcess] = useState(false);

  const sendData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setProcess(true);
      const payload = {
        firstName: firstname,
        lastName: lastname,
        emailAddress: email,
        organizationName: organizationname,
        organizationSize: organizationsize,
        location: location,
      };

      const response = await fetch(
        "https://sparkplug-api.onrender.com/email/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setOrganizationName("");
        setOrganizationSize("");
        setLocation("");
        toast.success(
          "Thank you for being an early access sign-up! Get ready to unlock exclusive benefits and insights firsthand!",
          { position: "top-center", autoClose: 2000, hideProgressBar: true }
        );
        setProcess(false);
      } else {
        toast.error(response.statusText);
        setProcess(false);
      }
    } catch (error) {
      console.log("Error:", error);
      setProcess(false);
    }
  };

  return (
    <>
      <button
        data-bs-toggle="modal"
        data-bs-target="#signupmodal"
        className="orange-shadow-btn ring-offset-background focus-visible:ring-ring inline-flex h-[45px] items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2 font-medium text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
        style={{ fontWeight: "700", fontSize: "14px" }}
      >
        Sign Up for Early Access
      </button>
      <div
        className="modal fade"
        id="signupmodal"
        aria-hidden="true"
        aria-labelledby="signupmodalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog  modal-md modal-dialog-centered">
          <div className="modal-content rounded-[22px]">
            <div className="modal-header">
              <span
                className="modal-title fs-5 font-bold"
                id="signupmodalLabel"
              >
                Sign Up for Early Access
              </span>
              <span
                data-bs-dismiss="modal"
                aria-label="Close"
                className="cursor-pointer"
              >
                <IoCloseCircle
                  className="close-icon"
                  size={28}
                  style={{ color: "#72747A" }}
                />
              </span>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => sendData(e)}>
                <div className="px-1">
                  <div className="row">
                    <div className="col-md">
                      <div className="signup-input">
                        <label className="input-label">First Name</label>
                        <input
                          type="text"
                          name="amount"
                          value={firstname}
                          placeholder="Enter first name"
                          className="form-control"
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="signup-input">
                        <label className="input-label">Last Name</label>
                        <input
                          type="text"
                          name="amount"
                          value={lastname}
                          placeholder="Enter last name"
                          className="form-control"
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="signup-input-with-icon">
                      <label className="input-label">Email Address</label>
                      <input
                        type="email"
                        name="amount"
                        value={email}
                        placeholder="Enter email address"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <CiMail className="input-icon" />
                    </div>
                    <div className="signup-input">
                      <label className="input-label">Organization Name</label>
                      <input
                        type="text"
                        value={organizationname}
                        name="amount"
                        placeholder="Enter organization name"
                        className="form-control"
                        onChange={(e) => setOrganizationName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="signup-input">
                      <label className="input-label">Organization Size</label>
                      <select
                        className="fund-selection form-select"
                        required
                        value={organizationsize}
                        onChange={(e) => setOrganizationSize(e.target.value)}
                      >
                        <option value={""} selected disabled>
                          -- Select Size --
                        </option>
                        <option value="1-100 members">1-100 members</option>
                        <option value="101-500 members">101-500 members</option>
                        <option value="501-2000 members">
                          501-2000 members
                        </option>
                        <option value="2001-5000 members">
                          2001-5000 members
                        </option>
                        <option value="5001+ members">5001+ members</option>
                      </select>
                    </div>

                    <LocationInput setLocation={setLocation} />
                  </div>

                  <div className="py-3 row gap-2 mx-1">
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        border: "1.5px solid #FF5841",
                        borderRadius: "25px",
                        color: "#ff5841",
                        backgroundColor: "#fff",
                      }}
                      className="px-3 h-[45px] col-md"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="col-md w-100  ring-offset-background focus-visible:ring-ring w-50 inline-flex h-[45px]  items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2  font-medium text-white  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                      style={{ fontWeight: "700", fontSize: "14px" }}
                      disabled={process}
                    >
                      {process && (
                        <div
                          className="spinner-border text-light spinner-border-sm m-2"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      Sign Up for Early Access
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForEarlyAccess;
