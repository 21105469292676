import React from "react";
import starimg from "../../imgs/review/star.png";
import quoteimg from "../../imgs/review/quote.png";

interface reviewcard {
  name: string;
  review: string;
  profile_img: string;
  organization: string;
}
const ReviewCard: React.FC<reviewcard> = ({
  name,
  review,
  profile_img,
  organization,
}) => {
  return (
    <div className="review-card">
      <div className="p-4">
        <img src={quoteimg} alt="quoteimg" className="quoteimg" />
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center">
            <img src={profile_img} alt="user" className="userimg" />
          </div>
          <div className="d-flex flex-col justify-content-between">
            <span className="font-bold fs-5">{name}</span>
            <span className="subheader mb-1 truncate">{organization}</span>
            <div className="d-flex gap-1">
              <img src={starimg} alt="star" className="starimg" />
              <img src={starimg} alt="star" className="starimg" />
              <img src={starimg} alt="star" className="starimg" />
              <img src={starimg} alt="star" className="starimg" />
              <img src={starimg} alt="star" className="starimg" />
            </div>
          </div>
        </div>
        <div>
          <p className="mt-3 text-gray-500">{review}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
