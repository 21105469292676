import React from "react";
import HeroImg from "../../imgs/hero-img.png";
import "./index.css";
import SignUpForEarlyAccess from "../model/SignUpForEarlyAccess";

const Hero = () => {
  return (
    <div>
      <div className="hero-section">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <img
            alt="Mobile Dashboard"
            className="block sm:hidden"
            height="100%"
            src={HeroImg}
            style={{
              objectFit: "cover",
            }}
            width="100%"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <br></br>
              <h1 className="text-5xl font-bold leading-tight hero-text">
                Increase <span className="orange-font">Donations.</span>
                <br />
                Inspire <span className="orange-font">Unity.</span>
                {"\n"}
              </h1>
              <p className="mt-4 grey-font desc-text-hero">
                Simplify Generosity: Effortless Giving for Members Anytime,
                Anywhere.
              </p>
              <div className="mt-6 flex flex-col sm:flex-row gap-2">
                <SignUpForEarlyAccess />

                {/* TODO: will add this back once working demo is achieved */}
                {/* <OutlinedButton label="Request Demo" /> */}
              </div>
            </div>
            <div className="flex justify-center">
              <div className="space-y-4 p-3">
                <img
                  alt="Dashboard"
                  className="hidden sm:block"
                  height="100%"
                  src={HeroImg}
                  style={{
                    objectFit: "cover",
                  }}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
