import React from "react";
import donation from "../../imgs/hero/donation.png";
import dotscontainer from "../../imgs/hero/dotscontainer.png";
import BlueArrows from "../../imgs/hero/blue-arrows-down.png";
import RedArrows from "../../imgs/hero/red-arrows-down.png";
import MemberList from "../../imgs/hero/member-list.png";
import DashboardImg from "../../imgs/hero/dashboard.png";
import GiveMobileView from "../../imgs/hero/give-mobile-view.png";
import FullScreenImageView from "../common/FullScreenImageView";

const Info = () => {
  return (
    <>
      <section className="w-full py-5 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800 donation-section-1">
        <div className="container px-4 md:px-6">
          <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div
              style={{ cursor: "pointer" }}
              className="mx-auto aspect-photo overflow-hidden rounded-xl"
            >
              <FullScreenImageView url={donation} />
            </div>
            <div className="space-y-4">
              <div className="d-flex align-items-center">
                {/* <img src={rectangle}  className="rectangle" alt="" />  */}
                <h2 className=" rectangle text-2xl font-bold tracking-tighter sm:text-3xl">
                  Effortlessly Manage{" "}
                  <span className="orange-font">Recurring Gifts</span>
                </h2>
              </div>
              <p className="info-desc">
                Keep track of continuous support through our Recurring Donations
                feature. View, adjust, and monitor ongoing contributions in one
                convenient place, ensuring a steady flow of support for your
                organization missions. Simplify your financial planning with
                predictable income.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full py-5 md:py-24 lg:py-32">
        <img src={dotscontainer} className="dot-container" alt="dots" />
        <img src={dotscontainer} className="dot-container-2" alt="dots" />
        <img src={RedArrows} className="red-arrows" alt="dots" />
        <img src={BlueArrows} className="blue-arrows" alt="dots" />
        <div className="container px-4 md:px-6">
          <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div className="space-y-4">
              <div className="d-flex align-items-center">
                {/* <img src={rectangle} className="rectangle" alt="" />  */}
                <h2 className="rectangle text-2xl font-bold tracking-tighter sm:text-3xl">
                  Insights Unlocked with{" "}
                  <span className="orange-font"> Data Driven Dashboard</span>
                </h2>
              </div>
              <p className="info-desc">
                Discover key insights and boost your donations with our
                Dashboard. It's designed to help you understand giving patterns,
                member engagement, and financial trends, enabling strategic
                decisions to enhance your organization funding and impact.
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="mx-auto aspect-photo overflow-hidden rounded-xl"
            >
              <FullScreenImageView url={DashboardImg} />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-5 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800 donation-section-1">
        <div className="container px-4 md:px-6">
          <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div
              style={{ cursor: "pointer" }}
              className="mx-auto aspect-photo overflow-hidden rounded-xl"
            >
              <FullScreenImageView url={GiveMobileView} />
            </div>
            <div className="space-y-4">
              <div className="d-flex align-items-center">
                {/* <img src={rectangle}  className="rectangle" alt=""  />   */}
                <h2 className="rectangle text-2xl font-bold tracking-tighter sm:text-3xl">
                  Mobile Giving <span className="orange-font"> Made Easy</span>
                </h2>
              </div>
              <p className="info-desc">
                Receive donations anytime, anywhere. Our streamlined mobile
                donation process enables your congregation to support your
                organization effortlessly with just a few taps. Safe, secure,
                and swift—facilitate giving with confidence and convenience.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full py-5 md:py-24 lg:py-32">
        <img src={dotscontainer} className="dot-container" alt="dots" />
        <img src={dotscontainer} className="dot-container-2" alt="dots" />
        <img src={RedArrows} className="red-arrows" alt="dots" />
        <img src={BlueArrows} className="blue-arrows" alt="dots" />
        <div className="container px-4 md:px-6">
          <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div className="space-y-4">
              <div className="d-flex align-items-center">
                {/* <img src={rectangle} alt="" className='mr-2 h-[30px] w-[5px] mt-[2px]' />   */}
                <h2 className="rectangle text-2xl font-bold tracking-tighter sm:text-3xl">
                  Manage Member{" "}
                  <span className="orange-font"> Contributions</span>
                </h2>
              </div>
              <p className="info-desc">
                Streamline management of member profiles and donations with this
                robust tool. Track engagement, monitor giving trends, and
                organize donation types to support your community’s growth and
                stewardship.{" "}
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="mx-auto aspect-photo overflow-hidden rounded-xl"
            >
              <FullScreenImageView url={MemberList} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Info;
