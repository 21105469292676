import React from "react";
import blog1 from "../../imgs/blog/blog-1.png";
import blog2 from "../../imgs/blog/blog-2.png";
import blog3 from "../../imgs/blog/blog-3.png";
import { FaCalendarAlt } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";

const Blog = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1324, min: 464 },
      items: 1,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  };
  return (
    <div className="container py-5">
      <div className="d-flex flex-col  align-items-center mb-4">
        <div>
          <span className="text-[#7D818E] text-[17px] font-medium">
            OUR BLOG
          </span>
        </div>
        <h1 className="fs-1 font-bold leading-tight">
          Check Our <span className="orange-font">Latest Blog</span>
        </h1>
      </div>

      <div className="lg:px-[200px] md:px-[50px] blog-carousel-class">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          infinite={true}
          slidesToSlide={1}
          partialVisible={true}
          pauseOnHover
        >
          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0">
            <div className="blog-img">
              <img src={blog1} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>03/13/2024</p>
              </div>
              <div className="blog-title font-medium mt-3">
                5 Data-Backed Reasons Why Digital Giving Boosts Organization
                Donations
              </div>
              <div className="mt-3  grey-font mb-5">
                Unlocking the Power of Generosity: How Digital Platforms
                Revolutionize Organization Donations
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://givegrace943.substack.com/p/5-data-backed-reasons-why-digital"
                  className="d-flex"
                >
                  {" "}
                  READ MORE &nbsp;
                  <HiOutlineChevronDoubleRight className="rightarrow" />
                </a>
              </div>
            </div>
          </article>

          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 ">
            <div className="blog-img">
              <img src={blog2} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>07/21/2023</p>
              </div>
              <div className="blog-title font-medium mt-3">
                The State of Church Giving: Trends and Statistics
              </div>
              <div className="mt-3  grey-font">
                Here are the most important (and often surprising) trends in
                church giving to know as you lead and manage your ministry in
                2024.
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.churchtrac.com/articles/the-state-of-church-giving"
                  className="d-flex"
                >
                  {" "}
                  READ MORE &nbsp;
                  <HiOutlineChevronDoubleRight className="rightarrow" />
                </a>
              </div>
            </div>
          </article>

          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 ">
            <div className="blog-img">
              <img src={blog3} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>27/02/2024</p>
              </div>
              <div className="blog-title font-medium mt-3">
                8 Online Donation Trends To Digitalize Your Nonprofit
              </div>
              <div className="mt-3  grey-font">
                The Rise of Online Donations: How Nonprofits Can Thrive
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.springly.org/en-us/blog/online-donations-8-digital-trends-nonprofit/"
                  className="d-flex"
                >
                  {" "}
                  READ MORE &nbsp;
                  <HiOutlineChevronDoubleRight className="rightarrow" />
                </a>
              </div>
            </div>
          </article>
        </Carousel>
      </div>
    </div>
  );
};

export default Blog;

/* <div className="flex items-center justify-center py-5">
        <div className="cards-container space-y-6 md:flex md:items-center md:justify-center md:flex-wrap md:space-y-1 md:space-x-6 md:gap-y-6 md:gap-x-5 lg:flex-nowrap lg:gap-y-0">
          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 ">
            <div className="blog-img">
              <img src={blog1} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>27/02/2024</p>
              </div>
              <div className="blog-title font-medium mt-3">
                Organization donation is the most valuable gift
              </div>
              <div className="mt-3  grey-font">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been.
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                READ MORE &nbsp;
                                <HiOutlineChevronDoubleRight className="rightarrow" />

              </div>
            </div>
          </article>

          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 ">
            <div className="blog-img">
              <img src={blog2} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>27/02/2024</p>
              </div>
              <div className="blog-title font-medium mt-3">
                Organization donation is the most valuable gift
              </div>
              <div className="mt-3  grey-font">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been.
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                READ MORE &nbsp;
                                <HiOutlineChevronDoubleRight className="rightarrow" />

              </div>
            </div>
          </article>

          <article className="bg-white rounded-xl blogcard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 ">
            <div className="blog-img">
              <img src={blog3} alt="blog-img" />
            </div>
            <div className="my-3">
              <div className="blog-date">
                <FaCalendarAlt className="calender-img" />
                <p>27/02/2024</p>
              </div>
              <div className="blog-title font-medium mt-3">
                Organization donation is the most valuable gift
              </div>
              <div className="mt-3  grey-font">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been.
              </div>
              <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                READ MORE &nbsp;
                                <HiOutlineChevronDoubleRight className="rightarrow" />

              </div>
            </div>
          </article>
        </div>
      </div> */

/* <div className="pb-12 pt-5 bg-white">
        <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:gap-16">
            <div className="blog-card  rounded-lg ">
              <div className="blog-img">
                <img src={blog1} alt="blog-img" />
              </div>
              <div className="my-3">
                <div className="blog-date">
                  <FaCalendarAlt className="calender-img" />
                  <p>27/02/2024</p>
                </div>
                <div className="blog-title font-medium mt-3">
                  Organization donation is the most valuable gift
                </div>
                <div className="mt-3  grey-font">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been.
                </div>
                <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                  READ MORE &nbsp;
                                  <HiOutlineChevronDoubleRight className="rightarrow" />

                </div>
              </div>
            </div>
            <div className="blog-card   rounded-lg ">
              <div className="blog-img">
                <img src={blog2} alt="blog-img" />
              </div>
              <div className="my-3">
                <div className="blog-date">
                  <FaCalendarAlt className="calender-img" />
                  <p>27/02/2024</p>
                </div>
                <div className="blog-title font-medium mt-3">
                  Organization donation is the most valuable gift
                </div>
                <div className="mt-3  grey-font">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been.
                </div>
                <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                  READ MORE &nbsp;
                                  <HiOutlineChevronDoubleRight className="rightarrow" />

                </div>
              </div>
            </div>
            <div className="blog-card  rounded-lg ">
              <div className="blog-img">
                <img src={blog3} alt="blog-img" />
              </div>
              <div className="my-3">
                <div className="blog-date">
                  <FaCalendarAlt className="calender-img" />
                  <p>27/02/2024</p>
                </div>
                <div className="blog-title font-medium mt-3">
                  Organization donation is the most valuable gift
                </div>
                <div className="mt-3  grey-font">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been.
                </div>
                <div className="mt-4 text-[12px] font-bold text-gray-700 d-flex more-hover">
                  READ MORE &nbsp;
                                  <HiOutlineChevronDoubleRight className="rightarrow" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */
