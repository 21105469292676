import React from 'react'
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

interface fullscreenimage{
    url:string;
}
const FullScreenImageView:React.FC<fullscreenimage> = ({url}) => {
    return (
        <PhotoProvider>
            <div className="foo">
                <PhotoView src={url}>
                    <img src={url} style={{ objectFit: 'cover' }} alt="" />
                </PhotoView>
            </div>
        </PhotoProvider>
    )
}
export default FullScreenImageView;